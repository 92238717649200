// src/Notes.js
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import Button from './components/Button';
import Input from './components/Input';
import Card from './components/Card';
import ErrorMessage from './components/ErrorMessage';
import LoadingSpinner from './components/LoadingSpinner';

// Encryption function
const encryptData = async (notes, password) => {
  const encoder = new TextEncoder();
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const iv = crypto.getRandomValues(new Uint8Array(12));
  
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    encoder.encode(password),
    { name: 'PBKDF2' },
    false,
    ['deriveBits', 'deriveKey']
  );
  
  const key = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: salt,
      iterations: 100000,
      hash: 'SHA-256'
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt']
  );
  
  const encryptedContent = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    key,
    encoder.encode(JSON.stringify(notes))
  );
  
  const encryptedData = btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedContent)));
  const saltString = btoa(String.fromCharCode.apply(null, salt));
  const ivString = btoa(String.fromCharCode.apply(null, iv));
  
  return { encryptedData, iv: ivString, salt: saltString };
};

// Decryption function
const decryptData = async (encryptedData, ivString, saltString, password) => {
  const decoder = new TextDecoder();
  const encoder = new TextEncoder();
  
  const salt = Uint8Array.from(atob(saltString), c => c.charCodeAt(0));
  const iv = Uint8Array.from(atob(ivString), c => c.charCodeAt(0));
  const data = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));
  
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    encoder.encode(password),
    { name: 'PBKDF2' },
    false,
    ['deriveBits', 'deriveKey']
  );
  
  const key = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: salt,
      iterations: 100000,
      hash: 'SHA-256'
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['decrypt']
  );
  
  const decryptedContent = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: iv },
    key,
    data
  );
  
  return JSON.parse(decoder.decode(decryptedContent));
};

const Notes = () => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [newTitle, setNewTitle] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingContent, setEditingContent] = useState('');
  const [editingTitle, setEditingTitle] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [linkSearchTerm, setLinkSearchTerm] = useState('');
  const [linkSearchResults, setLinkSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.token) {
      fetchNotes();
    }
  }, [user]);

  const fetchNotes = async () => {
    if (!user || !user.token) {
      setError('User not authenticated');
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get('/api/notes', {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      if (response.data.encryptedData && response.data.iv && response.data.salt) {
        const decryptedNotes = await decryptData(
          response.data.encryptedData,
          response.data.iv,
          response.data.salt,
          user.password
        );
        setNotes(decryptedNotes);
      } else {
        setNotes([]);
      }
    } catch (error) {
      setError('Failed to fetch notes. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredNotes = useMemo(() => {
    return notes.filter(note => 
      note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      note.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [notes, searchTerm]);

  const handleAddNote = async () => {
    if (!newTitle.trim() || !newNote.trim()) return;
    setIsLoading(true);
    const updatedNotes = [...notes, { title: newTitle, content: newNote }];
    try {
      await updateNotes(updatedNotes);
      setNotes(updatedNotes);
      setNewTitle('');
      setNewNote('');
    } catch (error) {
      setError('Failed to add note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditNote = async (index) => {
    if (editingTitle.trim() === '' || editingContent.trim() === '') return;
    setIsLoading(true);
    const updatedNotes = [...notes];
    updatedNotes[index] = { title: editingTitle, content: editingContent };
    try {
      await updateNotes(updatedNotes);
      setNotes(updatedNotes);
      setEditingIndex(null);
      setEditingTitle('');
      setEditingContent('');
    } catch (error) {
      setError('Failed to edit note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteNote = async (index) => {
    setIsLoading(true);
    const updatedNotes = notes.filter((_, i) => i !== index);
    try {
      await updateNotes(updatedNotes);
      setNotes(updatedNotes);
    } catch (error) {
      setError('Failed to delete note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateNotes = async (updatedNotes) => {
    try {
      if (!user || !user.password) {
        throw new Error('User not authenticated');
      }
      const { encryptedData, iv, salt } = await encryptData(updatedNotes, user.password);
      await axios.put('/api/notes', 
        { encryptedData, iv, salt },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
    } catch (error) {
      console.error('Failed to update notes:', error);
      throw new Error('Failed to update notes on the server: ' + error.message);
    }
  };

  const handleLinkSearch = (text) => {
    const match = text.match(/\[\[([^\]]+)\]\]/);
    if (match) {
      const searchTerm = match[1];
      setLinkSearchTerm(searchTerm);
      const results = notes.filter(note => 
        note.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setLinkSearchResults(results);
    } else {
      setLinkSearchTerm('');
      setLinkSearchResults([]);
    }
  };

  const createLink = (title) => {
    const existingNote = notes.find(note => note.title.toLowerCase() === title.toLowerCase());
    if (existingNote) {
      return `[[${existingNote.title}]]`;
    } else {
      const newNote = { title: title, content: '' };
      setNotes([...notes, newNote]);
      return `[[${title}]]`;
    }
  };

  const renderNoteContent = useMemo(() => (content) => {
    const parts = content.split(/(\[\[[^\]]+\]\])/);
    return parts.map((part, index) => {
      if (part.startsWith('[[') && part.endsWith(']]')) {
        const title = part.slice(2, -2);
        const linkedNote = notes.find(note => note.title === title);
        return (
          <span key={index} className="text-blue-500 cursor-pointer" onClick={() => {
            setEditingIndex(notes.findIndex(note => note.title === title));
            setEditingTitle(linkedNote ? linkedNote.title : title);
            setEditingContent(linkedNote ? linkedNote.content : '');
          }}>
            {title}
          </span>
        );
      }
      return part;
    });
  }, [notes, setEditingIndex, setEditingTitle, setEditingContent]);

  return (
    <div className="max-w-4xl mx-auto">
      <Card className="relative px-4 py-10 bg-white dark:bg-gray-800 shadow-lg sm:rounded-3xl sm:p-20">
        <div className="max-w-3xl mx-auto">
          <div>
            <h1 className="text-2xl font-semibold mb-6 text-gray-900 dark:text-white">My Notes</h1>
          </div>
          {error && <ErrorMessage message={error} />}
          <div className="mb-4">
            <Input
              type="text"
              placeholder="Search notes..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full"
            />
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              <ul className="py-8 space-y-4 text-gray-700 dark:text-gray-300">
                {filteredNotes.map((note, index) => (
                  <li key={index} className="flex items-center justify-between space-x-3">
                    {editingIndex === index ? (
                      <>
                        <Input
                          type="text"
                          className="flex-1 mb-2"
                          value={editingTitle}
                          onChange={(e) => setEditingTitle(e.target.value)}
                          placeholder="Title"
                        />
                        <textarea
                          className="w-full p-2 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
                          value={editingContent}
                          onChange={(e) => {
                            setEditingContent(e.target.value);
                            handleLinkSearch(e.target.value);
                          }}
                          rows={4}
                        />
                        {linkSearchResults.length > 0 && (
                          <ul className="absolute z-10 bg-white dark:bg-gray-700 border dark:border-gray-600 rounded mt-1 w-64">
                            {linkSearchResults.map((result, i) => (
                              <li
                                key={i}
                                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                                onClick={() => {
                                  setEditingContent(editingContent.replace(`[[${linkSearchTerm}]]`, createLink(result.title)));
                                  setLinkSearchResults([]);
                                }}
                              >
                                {result.title}
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="font-bold">{note.title}</span>
                        <p className="flex-1">{renderNoteContent(note.content)}</p>
                      </>
                    )}
                    <div className="flex space-x-2">
                      {editingIndex === index ? (
                        <Button onClick={() => handleEditNote(index)} variant="secondary">
                          Save
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setEditingIndex(index);
                            setEditingTitle(note.title);
                            setEditingContent(note.content);
                          }}
                          variant="secondary"
                        >
                          Edit
                        </Button>
                      )}
                      <Button onClick={() => handleDeleteNote(index)} variant="danger">
                        Delete
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                <div className="flex flex-col space-y-2">
                  <Input
                    type="text"
                    placeholder="New note title"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                  <textarea
                    className="w-full p-2 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    placeholder="New note content"
                    value={newNote}
                    onChange={(e) => {
                      setNewNote(e.target.value);
                      handleLinkSearch(e.target.value);
                    }}
                    rows={4}
                  />
                  {linkSearchResults.length > 0 && (
                    <ul className="absolute z-10 bg-white dark:bg-gray-700 border dark:border-gray-600 rounded mt-1 w-64">
                      {linkSearchResults.map((result, i) => (
                        <li
                          key={i}
                          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                          onClick={() => {
                            setNewNote(newNote.replace(`[[${linkSearchTerm}]]`, createLink(result.title)));
                            setLinkSearchResults([]);
                          }}
                        >
                          {result.title}
                        </li>
                      ))}
                    </ul>
                  )}
                  <Button
                    onClick={handleAddNote}
                    disabled={isLoading}
                  >
                    Add Note
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Notes;

// src/components/Card.js
import React from 'react';

const Card = ({ children, className = '' }) => {
  const baseStyle = 'bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden';

  return (
    <div className={`${baseStyle} ${className}`}>
      {children}
    </div>
  );
};

export default Card;

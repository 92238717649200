// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext'; // Add useAuth here
import { ThemeProvider } from './contexts/ThemeContext';
import Navigation from './components/Navigation';
import Register from './Register';
import Login from './Login';
import Notes from './Notes';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
            <Navigation />
            <main className="py-10">
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <Routes>
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/notes"
                    element={
                      <PrivateRoute>
                        <Notes />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/" element={<Navigate to="/notes" />} />
                </Routes>
              </div>
            </main>
          </div>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
